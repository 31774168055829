import React from 'react'
import Layout from '../../../components/layout/Layout'

const RanchingIndex = () => {
  return (
    <Layout>
      <div id="ranching" className="page-content">
        <section id="ranching-section">
          <div className="inner">
            <h2>Ranching</h2>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default RanchingIndex
